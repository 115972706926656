//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UMUM from "../../library/umum";


export default {
  data() {
    return {

      form : {
        id : '',
        periode_id  : '',
        ku_ra_strategis_indikator_capaian : '',
        pk_unit_sasaran_indikator_capaian : '',
        hari : '',
        bulan : '',
        tahun : '',
        worksheet_uraian : '',
        worksheet_capaian : '',
        worksheet_satuan : '',
        keterangan : '',
        nip : '',
        nip_verifikator : '',
        status : '',
        keterangan_status : '',
        ku_ra_strategis_indikator_uraian : '',
        ku_ra_target_indikator : '',
        ku_ra_strategis_indikator_satuan : '',
        ku_ra_strategis_uraian : '',
        pk_unit_sasaran_indikator_uraian : '',
        pk_unit_sasaran_indikator_target : '',
        pk_unit_sasaran_indikator_satuan : '',
        pk_unit_sasaran_uraian : '',
        pk_unit_sasaran_tahun : '',
        nama : '',
        gelar_depan : '',
        gelar_belakang : '',
        jabatan_uraian : '',
        unit_kerja_uraian : '',
        score : 0,
        score_pk_unit_sasaran_indikator : 0,
        
      },

      filter : {
        status : '',
        nip : '',
      },
      lampiran : {
        id : '',
        file : '',
        type : '',
        ref : '',
      },
     
      list_data : [],
      list_lampiran : [],

      page_first: 1,
      page_last: 0,
      page_limit : 8,
      cari_value: "",
      cek_load_data : true,

      mdl_lihat : false,
      mdl_add: false,
      mdl_edit: false,
      mdl_verifikasi : false,
      btn_add: false,
      mdl_kembalikan : false,
      mdl_list_lamp : false,
      mdl_lamp : false,

      UMUM : UMUM,
    }
  },
  methods: {


    getView : function(){
      console.log("dix");
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_kinerja_worksheet + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              page_limit : this.page_limit,
              cari_value: this.cari_value,
              status : this.filter.status,
              penilai : this.filter.nip,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              // console.log(res_data);
      });
    },


    addData : function(number) {
      fetch(this.$store.state.url.URL_kinerja_worksheet + "Add", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      fetch(this.$store.state.url.URL_kinerja_worksheet + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    rejectData : function(E){
      fetch(this.$store.state.url.URL_kinerja_worksheet + "rejectData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
          this.mdl_add = false
          this.mdl_lihat = false
      });

    },

    approveData : async function(E){

      if (this.form.score == 0 || this.form.score == '' ) {
        alert ("Mohon beri penilaian kualitas kerja terlebih dahulu")
        return false
      } 

      // await UMUM.confirmx("Teembe, Menaiki inono..?");
      fetch(this.$store.state.url.URL_kinerja_worksheet + "approveData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
          this.mdl_add = false
          this.mdl_lihat = false
      });

    },

    selectData : function(data){


        this.form.id = data.id ;
        this.form.periode_id  = data.periode_id ;
        this.form.ku_ra_strategis_indikator_capaian = data.ku_ra_strategis_indikator_capaian ;
        this.form.pk_unit_sasaran_indikator_capaian = data.pk_unit_sasaran_indikator_capaian ;
        this.form.hari = data.hari ;
        this.form.bulan = data.bulan ;
        this.form.tahun = data.tahun ;

        this.form.worksheet_uraian = data.worksheet_uraian ;
        this.form.worksheet_capaian = data.worksheet_capaian ;
        this.form.worksheet_satuan = data.worksheet_satuan ;

        this.form.keterangan = data.keterangan ;
        this.form.nip = data.nip ;
        this.form.nip_verifikator = data.nip_verifikator ;
        this.form.status = data.status ;
        this.form.keterangan_status = data.keterangan_status ;
        this.form.ku_ra_strategis_indikator_uraian = data.ku_ra_strategis_indikator_uraian ;
        this.form.ku_ra_target_indikator = data.ku_ra_target_indikator ;
        this.form.ku_ra_strategis_indikator_satuan = data.ku_ra_strategis_indikator_satuan ;
        this.form.ku_ra_strategis_uraian = data.ku_ra_strategis_uraian ;
        this.form.pk_unit_sasaran_indikator_uraian = data.pk_unit_sasaran_indikator_uraian ;
        this.form.pk_unit_sasaran_indikator_target = data.pk_unit_sasaran_indikator_target ;
        this.form.pk_unit_sasaran_indikator_satuan = data.pk_unit_sasaran_indikator_satuan ;
        this.form.pk_unit_sasaran_uraian = data.pk_unit_sasaran_uraian ;
        this.form.pk_unit_sasaran_tahun = data.pk_unit_sasaran_tahun ;
        this.form.nama = data.nama ;
        this.form.gelar_depan = data.gelar_depan ;
        this.form.gelar_belakang = data.gelar_belakang ;
        this.form.jabatan_uraian = data.jabatan_uraian ;
        this.form.unit_kerja_uraian = data.unit_kerja_uraian ;
        this.form.score = data.score ;
        this.form.score_pk_unit_sasaran_indikator = data.score_pk_unit_sasaran_indikator ;

        this.getLamp(this.form.id)
    },

    getLamp : function(data){
      fetch(this.$store.state.url.URL_kinerja_worksheet + "getLamp", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              ref: this.form.id,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_lampiran = res_data;
              // console.log(res_data)
      });
    },
    selectLampiran(data){
      this.lampiran = data
    },


    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing: function(index) {
          var idx = (this.page_first - 1) * this.page_limit + index;
          return idx;
        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {

    var get_profile = JSON.parse(localStorage.profile);
    this.filter.nip = get_profile.profile.nip;





    this.cari_data();
  },
}
